import { YorN } from 'ncp-api-supporter';

export const canChangeUseState = (to: YorN): boolean => {
  if (to === 'Y') {
    return confirm(window.$t('PRODUCT.ICON.CONFIRM_USE'));
  }
  return confirm(window.$t('PRODUCT.ICON.CONFIRM_NOT_USE'));
};

// TODO: 이미지 관련 벨리데이션 추가
